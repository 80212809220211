import { forwardRef, Ref, useImperativeHandle, useState } from 'react'
import { Button, Form } from 'antd'
import {
  ExperiencesData,
  RefStepInterface,
  StepProps,
} from '../../../models/FormDataModels'
import { useIntl } from 'react-intl'
import JobItem from '../../job-item/JobItem'
import { getJobs, selectJobs } from '../../../reducers/JobReducer'
import { useSelector } from 'react-redux'
import Loading from '../../utils/Loading'
import TextArea from 'antd/es/input/TextArea'
import { stringRule } from '../../../utils/rules'
import {
  getExperienceLevels,
  selectExperienceLevels,
} from '../../../reducers/ExperienceLevelReducer'
import { useAppDispatch } from '../../../reducers/hooks'
import MissingData from '../../utils/MissingData'

const Experiences = forwardRef(
  ({ data, updateData, hasError }: StepProps, ref: Ref<RefStepInterface>) => {
    useImperativeHandle(ref, () => ({ handleNext }))

    async function handleNext() {
      return await form
        .validateFields()
        .then((result) => {
          //TODO: Require at least one job to be ticked
          return true
        })
        .catch((err) => {
          return false
        })
    }

    const intl = useIntl()
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const jobs = useSelector(selectJobs())
    const levels = useSelector(selectExperienceLevels())

    const updateExperiencesData = (partial: Partial<ExperiencesData>) =>
      updateData({
        experiences: {
          ...data.experiences,
          ...partial,
        },
      })

    const fetchMissingData = () => {
      setIsLoading(true)
      Promise.all([dispatch(getJobs()), dispatch(getExperienceLevels())]).then(
        (values) => setIsLoading(false)
      )
    }

    return jobs.length > 0 && levels.length > 0 ? (
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(_, allFields) => {
          updateExperiencesData(allFields)
        }}
        initialValues={data.experiences}
      >
        {jobs.length > 0 ? (
          jobs.map((job) => (
            <JobItem
              key={job.id}
              job={job}
              interest={data.experiences.interests[job.id]}
            ></JobItem>
          ))
        ) : (
          <Loading></Loading>
        )}

        <Form.Item
          label={intl.formatMessage({ id: 'MORE_INFOS' })}
          rules={[stringRule(intl)]}
          name={'comment'}
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    ) : (
      <MissingData onClick={fetchMissingData} loading={isLoading} />
    )
  }
)

Experiences.displayName = 'Experiences'

export default Experiences
