import { Typography } from 'antd'

const { Title, Paragraph } = Typography
const LegalNotice = () => {
  return (
    <>
      <Title level={2}>MENTIONS LEGALES</Title>
      <Paragraph>
        Conformément aux dispositions des Articles 6-III et 19 de la Loi
        n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique
        (L.C.E.N.), il est porté à la connaissance des utilisateurs et visiteurs
        du site web https://recrutement.polefestif.fr les présentes mentions
        légales.
        <br />
        <br />
        Le présent site web est édité par : <br />
        NOCTIS EVENT SAS
        <br />
        <br />
        Société par actions simplifiée au capital social de 12 203,90 Euros
        <br />
        Ayant son siège social à Paris (75008) – 38 avenue des Champs-Elysées –
        France, inscrite au RCS de Paris sous le numéro 433 855 103
        <br />
        Tél. +33 (0)1 42 25 95 00
        <br />
        contact@paris-society.com
        <br />
        <br />
        N° de TVA intracommunautaire : FR18 433 855 103
        <br />
        N° siret : 433 855 103 00043
        <br />
        <br />
        Le présent site web est hébergé par OVH.
        <br />
        Siège social à Roubaix (59100) – 2 rue Kellermann – France.
        <br />
        Numéro de téléphone : 09 72 10 10 07
        <br />
        <br />
        Le Directeur de la publication du présent site est Laurent de Gourcuff
        en qualité de Président.
      </Paragraph>
      <Title level={4}>CREDITS</Title>
      <Paragraph>
        Le présent site web a été imaginé et conçu par : Supplément Bacon –
        Développeur Front-End & Ui/Ux Designer.
        <br />
        <br />
        Néanmoins, si un crédit photo vous semble manquant, veuillez-nous en
        excuser et nous contacter à l’adresse donnees.privees@paris-society.com.
      </Paragraph>
      <Title level={4}>PROPRIETE INTELLECTUELLE</Title>
      <Paragraph>
        Tous les éléments et contenus du Site et de ses comptes sur les réseaux
        sociaux, qu&apos;ils soient visuels ou sonores, les textes, mises en
        page, illustrations, photographies, vidéos, bases de données, éléments
        sonores, documents et autres éléments, y compris la technologie
        sous-jacente, sont la propriété exclusive de Noctis Event et/ou de tiers
        ayant autorisé Noctis Event à les utiliser et sont protégés par les
        droits de propriété intellectuelle et notamment le droit d&apos;auteur,
        des marques, des noms de domaine, des bases de données et des brevets.
        Toute reproduction totale ou partielle des éléments accessibles sur le
        Site est strictement interdite et est protégée par les lois françaises
        et internationales relatives à la propriété intellectuelle. Celles-ci ne
        concèdent aucune cession, licence, ni aucun droit autre que celui de
        consulter le Site et les comptes des réseaux sociaux de Noctis Event.
      </Paragraph>
      <Paragraph>
        En particulier, les marques, logos, dénominations sociales, sigles, noms
        commerciaux, enseignes et/ou nom de domaine de Noctis Event et/ou de ses
        partenaires commerciaux mentionnés dans le Site constituent des signes
        distinctifs insusceptibles d’utilisation sans l’autorisation expresse et
        préalable de leur titulaire.
      </Paragraph>
      <Paragraph>
        Toute représentation et/ou reproduction et/ou exploitation partielle ou
        totale de ces signes distinctifs est donc prohibée et constitutif de
        contrefaçon de marque, en application des dispositions du Code de la
        Propriété intellectuelle.
      </Paragraph>
      <Paragraph>
        Toute création de liens vers le Site ou les comptes de réseaux sociaux
        de Noctis Event, tout framing du Site, et plus généralement toute
        utilisation du Contenu, est soumise à l&apos;autorisation préalable et
        expresse de Noctis Event qui pourra être révoquée à tout moment à la
        seule discrétion de celui -ci. Noctis Event se réserve le droit de
        demander la suppression de tout lien vers le Site qui n&apos;aurait pas
        été, ou ne serait plus, autorisé et de demander des dommages et intérêts
        en réparation du préjudice subi de ce fait. Les sites extérieurs à
        Noctis Event ayant un lien hypertexte vers le Site ne sont pas sous
        contrôle de Noctis Event, qui décline toute responsabilité quant à leur
        contenu.
      </Paragraph>
      <Paragraph>
        Est également interdite toute extraction ou réutilisation de parties
        quantitativement ou qualitativement substantielles du contenu d’une des
        bases de données de ce Site sans autorisation préalable de Noctis Event.
      </Paragraph>
    </>
  )
}

export default LegalNotice
