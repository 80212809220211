import { createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { ExperienceLevel } from '../models/User'
import { EXPERIENCE_LEVELS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'

// utils
// @types

// ----------------------------------------------------------------------
type State = {
  isLoading: boolean
  error: Error | string | null
  items: ExperienceLevel[]
}

const initialState: State = {
  isLoading: false,
  error: null,
  items: [],
}

const slice = createSlice({
  name: 'experienceLevel',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Admin
    getItemsSuccess(state, action) {
      state.isLoading = false
      state.items = action.payload
    },
  },
})

// Reducer
export default slice.reducer

/**
 * Actions
 */
export function getExperienceLevels() {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.get(EXPERIENCE_LEVELS_URL).then((response) => {
      const items = response.data.data
      dispatch(slice.actions.getItemsSuccess(items))
    })
  }
}

/**
 * Selectors
 */

const selectRawItems = (state: RootState): ExperienceLevel[] =>
  state.experienceLevel.items

export const selectExperienceLevels = () =>
  createSelector<any, ExperienceLevel[]>(
    [selectRawItems],
    (levels: ExperienceLevel[]) => levels
  )
