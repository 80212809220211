import { Tag } from 'antd'
import { useSelector } from 'react-redux'
import { Availabilty } from '../../../models/User'
import { selectDays } from '../../../reducers/DayReducer'

interface Props {
  availabilities: Availabilty[]
}

const AvailabiltyColumn = ({ availabilities }: Props) => {
  const days = useSelector(selectDays())

  const isAvailableOnDayWithId = (dayId: number) =>
    availabilities.filter((a) => a.day.id === dayId).length > 0

  return (
    <>
      {days.map(
        (day) =>
          isAvailableOnDayWithId(day.id) && (
            <Tag key={day.id} color={'blue'}>
              {day.label}
            </Tag>
          )
      )}
    </>
  )
}

export default AvailabiltyColumn
