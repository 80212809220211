import { createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { Job } from '../models/User'
import { JOBS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'

// utils
// @types

// ----------------------------------------------------------------------
type State = {
  isLoading: boolean
  error: Error | string | null
  items: Job[]
}

const initialState: State = {
  isLoading: false,
  error: null,
  items: [],
}

const slice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Admin
    getItemsSuccess(state, action) {
      state.isLoading = false
      state.items = action.payload
    },
  },
})

// Reducer
export default slice.reducer

/**
 * Actions
 */
export function getJobs() {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.get(JOBS_URL).then((response) => {
      const items = response.data.data
      dispatch(slice.actions.getItemsSuccess(items))
    })
  }
}

/**
 * Selectors
 */

const selectRawItems = (state: RootState): Job[] => state.job.items

export const selectJobs = () =>
  createSelector<any, Job[]>([selectRawItems], (jobs: Job[]) => jobs)
