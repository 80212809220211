import { Breadcrumb, Card, Col, Row, Statistic } from 'antd'
import {
  CheckOutlined,
  ClockCircleOutlined,
  StopOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { getHome } from '../../services/HomeService'
import { HomeData } from '../../models/HomeModels'
import Loading from '../../components/utils/Loading'

const Home = () => {
  const intl = useIntl()

  const [data, setData] = useState<HomeData | undefined>(undefined)

  useEffect(() => {
    getHome().then((data) => setData(data))
  }, [])

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      {data ? (
        <Row gutter={[16, 16]}>
          <Col md={6} sm={12} xs={24}>
            <Card bordered={false}>
              <Statistic
                title={intl.formatMessage({ id: 'ADMIN.HOME.CANDIDATES' })}
                value={data.candidates}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          {data.statuses.map((status, index) => (
            <Col key={index} md={6} sm={12} xs={24}>
              <Card bordered={false}>
                <Statistic
                  title={status.name}
                  value={status.candidates}
                  valueStyle={{ color: status.color }}
                />
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default Home
