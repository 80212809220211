import CandidateFormWrapper from '../components/candidate-form/CandidateFormWrapper'

const CandidateForm = () => {
  return (
    <>
      <CandidateFormWrapper />
    </>
  )
}

export default CandidateForm
