import { Button, Result, Typography } from 'antd'
import { useIntl } from 'react-intl'

interface Props {
  onClick: () => void
  loading: boolean
}

const MissingData = ({ onClick, loading }: Props) => {
  const intl = useIntl()

  return (
    <Result
      icon={<></>}
      subTitle={intl.formatMessage({ id: 'ERROR_WHILE_FETCHING_DATA' })}
      extra={[
        <Button key={0} type="primary" onClick={onClick} loading={loading}>
          {intl.formatMessage({ id: 'TRY_AGAIN' })}
        </Button>,
      ]}
    />
  )
}

export default MissingData
