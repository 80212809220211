import { useIntl } from 'react-intl'
import TextArea from 'antd/es/input/TextArea'
import { Button, Form, Input, Space } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import ColorSelect from '../../custom-inputs/ColorSelect'
import { requiredRule, stringRule } from '../../../utils/rules'
import { createStatus } from '../../../reducers/StatusReducer'
import { useSelector } from 'react-redux'

interface Props {
  onFinish: () => void
}

const Create = ({ onFinish }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [form] = Form.useForm()

  const { isLoading } = useAppSelector((state) => state.status)

  const onSubmit = async () => {
    dispatch(createStatus(form.getFieldsValue())).then(() => {
      form.resetFields()
      onFinish()
    })
  }

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="name"
        label={intl.formatMessage({ id: 'NAME' })}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <ColorSelect
        name="color"
        label={intl.formatMessage({ id: 'COLOR' })}
        rules={[requiredRule(intl)]}
      />
      <Form.Item
        name="emailContent"
        label={intl.formatMessage({ id: 'ADMIN.STATUS.TABLE.EMAIL_CONTENT' })}
        rules={[stringRule(intl)]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {intl.formatMessage({ id: 'SUBMIT' })}
          </Button>
          <Button
            type="default"
            htmlType="reset"
            disabled={isLoading}
            onClick={() => onFinish()}
          >
            {intl.formatMessage({ id: 'CANCEL' })}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default Create
