import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Card, Space, Table, Tag, Typography } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import StatusModal from '../../components/status/StatusModal'
import { Status } from '../../models/User'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  openModalForAction,
  selectStatus,
  setQuery,
  setSelectedId,
} from '../../reducers/StatusReducer'
import { getStatus } from '../../reducers/StatusReducer'
import DeleteCandidatesByStatus from '../../components/admin/DeleteCandidatesByStatus'

const { Text } = Typography

const StatusManager = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const status = useSelector(selectStatus())

  const { isLoading, query, modalIsOpen } = useAppSelector(
    (state) => state.status
  )

  const columns: ColumnsType<Status> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: 'ADMIN.STATUS.TABLE.NAME' }),
      render: (record: Status) => (
        <Tag key={record.id} color={record.color}>
          {record.name}
        </Tag>
      ),
    },
    {
      key: 'candidatesCount',
      dataIndex: 'candidatesCount',
      title: intl.formatMessage({ id: 'ADMIN.STATUS.TABLE.CANDIDATES_COUNT' }),
    },
    {
      key: 'emailContent',
      title: intl.formatMessage({
        id: 'ADMIN.STATUS.TABLE.EMAIL_CONTENT',
      }),
      render: (record: Status) =>
        record.emailContent ? <Text>{record.emailContent}</Text> : <Tag>X</Tag>,
    },
    {
      fixed: 'left',
      key: 'name',
      render: (record: Status) => (
        <Space direction="horizontal">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              dispatch(setSelectedId(record.id))
              dispatch(openModalForAction('update'))
            }}
          />
          {/* CANNOT DELETE THE FIRST STATUS */}
          {record.id !== 1 || record.candidatesCount === 0 ? (
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                dispatch(setSelectedId(record.id))
                dispatch(openModalForAction('delete'))
              }}
            />
          ) : (
            <></>
          )}
          <DeleteCandidatesByStatus
            id={record.id}
            onSuccess={() => {
              dispatch(getStatus(query))
            }}
          />
        </Space>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Status> | SorterResult<Status>[]
  ) => {
    const newQuery = {
      pagination,
      filters: { ...query.filters, ...filters },
      ...sorter,
    }

    dispatch(setQuery(newQuery))
    dispatch(getStatus(newQuery))
  }

  const handleAddNew = () => {
    dispatch(openModalForAction('create'))
  }

  useEffect(() => {
    if (modalIsOpen) {
      return
    }
    dispatch(getStatus(query))
  }, [modalIsOpen])

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Status</Breadcrumb.Item>
      </Breadcrumb>

      <Card
        bordered={false}
        bodyStyle={{ padding: 0 }}
        title={
          <Space
            style={{
              justifyContent: 'right',
              marginTop: 20,
              display: 'flex',
              marginBottom: 20,
            }}
          >
            <Button onClick={handleAddNew}>
              {intl.formatMessage({ id: 'ADD' })}
            </Button>
          </Space>
        }
      >
        <Table
          //   scroll={{ x: 1500 }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={status}
          pagination={query.pagination}
          loading={isLoading}
          onChange={handleTableChange}
        />
      </Card>
      <StatusModal />
    </>
  )
}

export default StatusManager
