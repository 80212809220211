import { Table, Typography } from 'antd'

const { Title, Paragraph } = Typography
const DataPrivacy = () => {
  return (
    <>
      <Title level={2}>Politique de protection des données</Title>
      <>
        <Title level={4}>1. Introduction</Title>
        <Paragraph>
          Dans le cadre de son activité, NOCTIS EVENT est amenée à collecter et
          à traiter des données à caractère personnel relatives aux visiteurs du
          site internet https://recrutement.polefestif.fr <br />
          L&apos;objectif principal de ce document est de regrouper dans un
          format concis, transparent, compréhensible et aisément accessible des
          informations concernant les traitements de données mis en œuvre pour
          vous permettre de comprendre dans quelles conditions vos données sont
          traitées.
        </Paragraph>
        <Title level={4}>
          2. Une collecte loyale et transparente de vos données
        </Title>
        <Paragraph>
          Dans un souci de loyauté et de transparence vis-à-vis de ses visiteurs
          NOCTIS EVENT prend soin d&apos;informer les personnes concernées de
          chaque traitement qu&apos;il met en œuvre par des mentions
          d&apos;information.  <br />
          Ces données sont collectées loyalement. Aucune collecte n&apos;est
          effectuée à l&apos;insu des personnes et sans qu&apos;elles en soient
          informées.
        </Paragraph>
        <Title level={4}>
          3. Une utilisation légitime et proportionnée de vos données
        </Title>
        <Paragraph>
          Lorsque NOCTIS EVENT est amenée à traiter des données, elle le fait
          pour des finalités spécifiques : chaque traitement de données mis en
          œuvre poursuit une finalité légitime, déterminée et explicite.  <br />
          Pour chacun des traitements mis en œuvre, elle s&apos;engage à ne
          collecter et n&apos;exploiter que des données adéquates, pertinentes
          et limitées à ce qui est nécessaire au regard des finalités pour
          lesquelles elles sont traitées.  <br />
          NOCTIS EVENT veille à ce que les données soient, si nécessaire, mises
          à jour et à mettre en œuvre des procédés pour permettre
          l&apos;effacement ou la rectification des données inexactes.
        </Paragraph>
        <Title level={4}>
          4. Les données à caractère personnel que nous traitons
        </Title>
        <Paragraph>
          Dans le cadre des traitements de données à caractère personnel dont
          les finalités vous seront présentées ci-après, NOCTIS EVENT collecte
          et traite les catégories de données suivantes :
          <Table
            pagination={false}
            columns={[
              { title: 'Catégorie de données', dataIndex: 'cat', key: 'cat' },
              {
                title: 'Principales données de la catégorie',
                dataIndex: 'data',
                key: 'data',
              },
            ]}
            dataSource={[
              { cat: "Données d'identification", data: 'Prénom, nom, ' },
              { cat: 'Coordonnées', data: 'Prénom, nom, ' },
              {
                cat: 'Informations relatives à la vie personnelle',
                data: 'E-mail, téléphone,',
              },
              {
                cat: 'Informations relatives à la vie professionnelle',
                data: (
                  <ul>
                    <li>Fonctions auxquelles le candidat postule</li>
                    <li>
                      Niveau d&apos;expérience (novice, moyen, expert) sur les
                      fonctions
                    </li>
                    <li>Disponibilités (journée, soirée, nuit)</li>
                    <li>
                      Curriculum vitae et les données incluses qui y sont
                      incluses.
                    </li>
                  </ul>
                ),
              },
              {
                cat: "Informations d'ordre économique et financier",
                data: '',
              },
              {
                cat: 'Données de connexion',
                data: "IP (non stocké mais apparait dans les logs serveurs). User agent de l'appareil (IOS ou Android + version d'OS)",
              },
              { cat: 'Cookies et Pixel', data: '' },
            ]}
          />
        </Paragraph>
        <Title level={4}>
          5. Les bases juridiques et les finalités de nos traitements de données
        </Title>
        <Paragraph>
          Les traitements de données mis en œuvre par NOCTIS EVENT poursuivent
          les finalités identifiées ci-dessous et sont fondés sur les bases
          juridiques suivantes :
          <Table
            pagination={false}
            columns={[
              {
                title: 'Base juridique du traitement',
                dataIndex: 'base',
                key: 'base',
              },
              {
                title: 'Finalités',
                dataIndex: 'final',
                key: 'final',
              },
            ]}
            dataSource={[
              { base: 'Consentement ', final: 'Recueillir les données' },
              {
                base: 'Intérêt légitime de la société ',
                final:
                  "Envoi d'email de confirmation de réception de la candidature",
              },
            ]}
          />
        </Paragraph>
        <Title level={4}>6. Les destinataires de vos données</Title>
        <Paragraph>
          Sont destinataires de vos données chacun pour ce qui les concerne :
          <ul>
            <li>les sous-traitants de NOCTIS EVENT,</li>
            <li>Les fournisseurs et prestataires du site internet,</li>
            <li>la direction du festif,</li>
            <li>la direction des Ressources Humaines,</li>
            <li>la direction des systèmes d&apos;informations,</li>
            <li>les autres entités du groupe Paris Society le cas échéant.</li>
          </ul>
          Nous veillons à ce que seules les personnes habilitées puissent avoir
          accès à ces données.
        </Paragraph>
        <Title level={4}>7. Les transferts de vos données</Title>
        <Paragraph>
          Dans le cadre de l&apos;utilisation des services de Mailgun (pour
          l&apos;envoie des emails de confirmation), des données sont
          transférées uniquement sur des serveurs dans l&apos;Union Européenne
        </Paragraph>
        <Title level={4}>
          8. Les durées pour lesquelles nous conservons vos données
        </Title>
        <Paragraph>
          NOCTIS EVENT fait en sorte que les données ne soient conservées sous
          une forme permettant l&apos;identification des personnes concernées
          que pendant une durée nécessaire au regard des finalités pour
          lesquelles elles sont traitées.  <br />
          Les durées de conservation que nous appliquons à vos données à
          caractère personnel sont proportionnées aux finalités pour lesquelles
          elles ont été collectées.  <br />
          Plus précisément, nous organisons notre politique de conservation des
          données de la manière suivante :
          <Table
            pagination={false}
            columns={[
              {
                title: 'Données et finalités',
                dataIndex: 'base',
                key: 'base',
              },
              {
                title: 'Durée de conservation',
                dataIndex: 'final',
                key: 'final',
              },
            ]}
            dataSource={[
              { base: 'Gestion des CV', final: '2 ans' },
              {
                base: "Demande d'exercice des droits",
                final:
                  "Durée de traitement de la demande augmenté d'une conservation en archive pour les durées de prescription et de conservation obligatoire",
              },
            ]}
          />
        </Paragraph>
        <Title level={4}>9. La sécurité des données personnelles</Title>
        <Paragraph>
          NOCTIS EVENT a mis en place des mesures techniques et
          organisationnelles adaptées au degré de sensibilité des données
          personnelles, en vue d&apos;assurer l&apos;intégrité et la
          confidentialité des données et de les protéger contre toute intrusion
          malveillante, toute perte, altération ou divulgation à des tiers non
          autorisés.  <br />
          Néanmoins, la sécurité et la confidentialité des données personnelles
          reposent sur les bonnes pratiques de chacun, ainsi la personne
          concernée est invitée à rester vigilante sur la question.
        </Paragraph>
        <Title level={4}>10. La sous-traitance</Title>
      </>
      <>
        <Paragraph>
          Lorsque NOCTIS EVENT recourt à un prestataire, elle ne lui communique
          des données à caractère personnel qu&apos;après avoir obtenu de ce
          dernier un engagement et des garanties sur sa capacité à répondre à
          ces exigences de sécurité et de confidentialité.  <br />
          Nous concluons avec nos sous-traitants dans le respect de nos
          obligations légales et réglementaires des contrats définissant
          précisément les conditions et modalités de traitement des données
          personnelles par ces derniers.
        </Paragraph>
        <Title level={4}>11. Cookies</Title>
        <Paragraph>
          Seuls des cookies « fonctionnels » sont présent sur ce site pour en
          assurer le bon fonctionnement.
        </Paragraph>
        <Title level={4}>12. Les droits qui vous sont reconnus</Title>
        <Paragraph>
          NOCTIS EVENT est particulièrement soucieuse du respect des droits qui
          vous sont accordés dans le cadre des traitements de données
          qu&apos;elle met en œuvre, pour vous garantir des traitements
          équitables et transparents compte tenu des circonstances particulières
          et du contexte dans lesquels vos données personnelles sont traitées.
        </Paragraph>
        <Title level={4}>12.1 Votre droit d&apos;accès</Title>
        <Paragraph>
          A ce titre, vous avez la confirmation que vos données personnelles
          sont ou ne sont pas traitées et lorsqu&apos;elles le sont, vous
          disposez du droit de demander une copie de vos données et des
          informations concernant :
          <ul>
            <li>les finalités du traitement ;</li>
            <li>les catégories de données personnelles concernées ;</li>
            <li>
              les destinataires ou catégories de destinataires ainsi que, le cas
              échéant si de telles communication devaient être réalisées, les
              organisations internationales auxquelles les données personnelles
              ont été ou seront communiquées, en particulier les destinataires
              qui sont établis dans des pays tiers ;
            </li>
            <li>
              lorsque cela est possible, la durée de conservation des données
              personnelles envisagée ou, lorsque ce n&apos;est pas possible, les
              critères utilisés pour déterminer cette durée ;
            </li>
            <li>
              l&apos;existence du droit de demander au responsable du traitement
              la rectification ou l&apos;effacement de vos données personnelles,
              du droit de demander une limitation du traitement de vos données
              personnelles, du droit de vous opposer à ce traitement ;
            </li>
            <li>
              le droit d&apos;introduire une réclamation auprès d&apos;une
              autorité de contrôle ;
            </li>
            <li>
              des informations relatives à la source des données quand elles ne
              sont pas collectées directement auprès des personnes concernées ;
            </li>
            <li>
              l&apos;existence d&apos;une prise de décision automatisée, y
              compris de profilage, et dans ce dernier cas, des informations
              utiles concernant la logique sous-jacente, ainsi que
              l&apos;importance et les conséquences prévues de ce traitement
              pour les personnes concernées.
            </li>
          </ul>
        </Paragraph>
        <Title level={4}>
          12.2 Votre droit à la rectification de vos données
        </Title>
        <Paragraph>
          Vous pouvez nous demander à ce que vos données personnelles soient,
          selon les cas, rectifiées, complétées si elles sont inexactes,
          incomplètes, équivoques, périmées.
        </Paragraph>
        <Title level={4}>
          12.3 Votre droit à l&apos;effacement de vos données
        </Title>
        <Paragraph>
          Vous pouvez nous demander l&apos;effacement de vos données
          personnelles dans les cas prévus par la législation et la
          réglementation.  <br />
          Votre attention est attirée sur le fait que le droit à
          l&apos;effacement des données n&apos;est pas un droit général et
          qu&apos;il ne pourra y être fait droit que si un des motifs prévus
          dans la réglementation applicable est présent.
        </Paragraph>
        <Title level={4}>
          12.4 Votre droit à la limitation des traitements de données
        </Title>
        <Paragraph>
          Vous pouvez demander la limitation du traitement de vos données
          personnelles dans les cas prévus par la législation et la
          réglementation.
        </Paragraph>
        <Title level={4}>
          12.5 Votre droit de vous opposer aux traitements de données
        </Title>
        <Paragraph>
          Vous disposez du droit de vous opposer à tout moment, pour des raisons
          tenant à votre situation particulière, à un traitement de vos données
          à caractère personnel dont la base juridique est l&apos;intérêt
          légitime poursuivi par le responsable du traitement (cf. article
          ci-dessus sur la base juridique du traitement).  <br />
          En cas d&apos;exercice d&apos;un tel droit d&apos;opposition, nous
          veillerons à ne plus traiter vos données à caractère personnel dans le
          cadre du traitement concerné sauf si nous pouvons démontrer que nous
          pouvons avoir des motifs légitimes et impérieux pour maintenir ce
          traitement. Ces motifs devront être supérieurs à vos intérêts et à vos
          droits et libertés, ou le traitement se justifier pour la
          constatation, l&apos;exercice ou la défense de droits en justice. Vous
          disposez du droit de vous opposer à la sollicitation personnalisée.
        </Paragraph>
        <Title level={4}>
          12.6 Votre droit à la portabilité de vos données
        </Title>
        <Paragraph>
          Vous disposez du droit à la portabilité de vos données personnelles.
          Nous attirons votre attention sur le fait qu&apos;il ne s&apos;agit
          pas d&apos;un droit général. En effet, toutes les données de tous les
          traitements ne sont pas portables et ce droit ne concerne que les
          traitements automatisés à l&apos;exclusion des traitements manuels ou
          papiers.
          <br />
          Ce droit est limité aux traitements dont la base juridique est votre
          consentement ou l&apos;exécution des mesures précontractuelles ou
          d&apos;un contrat.
          <br />
          Ce droit n&apos;inclut ni les données dérivées ni les données
          inférées, qui sont des données personnelles créées par NOCTIS EVENT.
        </Paragraph>
        <Title level={4}>12.7 Votre droit de retirer votre consentement</Title>
        <Paragraph>
          Lorsque les traitements de données que nous mettons en œuvre sont
          fondés sur votre consentement, vous pouvez le retirer à n&apos;importe
          quel moment. Nous cessons alors de traiter vos données à caractère
          personnel sans que les opérations antérieures pour lesquelles vous
          aviez consenti ne soient remises en cause.
        </Paragraph>
        <Title level={4}>12.8 Votre droit d&apos;introduire un recours</Title>
        <Paragraph>
          Vous avez le droit d&apos;introduire une réclamation auprès de la Cnil
          (3 place de Fontenoy 75007 Paris) sur le territoire français et ce
          sans préjudice de tout autre recours administratif ou juridictionnel.
        </Paragraph>
        <Title level={4}>
          12.9 Votre droit de définir des directives post-mortem
        </Title>
        <Paragraph>
          Vous avez la possibilité de définir des directives particulières
          relatives à la conservation, à l&apos;effacement et à la communication
          de vos données personnelles après votre décès selon les modalités
          ci-après définies. Ces directives particulières ne concerneront que
          les traitements mis en œuvre par nos soins et seront limitées à ce
          seul périmètre. Vous disposerez également, lorsque cette personne aura
          été désignée par le pouvoir exécutif, définir des directives générales
          aux mêmes fins.
        </Paragraph>
        <Title level={4}>
          12.10 Les modalités d&apos;exercice de vos droits
        </Title>
        <Paragraph>
          Tous les droits énumérés ci-avant peuvent être exercés à
          l&apos;adresse électronique suivante donnees.privees@paris-society.com
          ou par courrier en s&apos;adressant à NOCTIS EVENT 38 avenue des
          Champs Elysées 75008 PARIS, en justifiant de votre identité par tout
          moyen.
        </Paragraph>
        <Title level={4}>13. Modification du présent document</Title>
        <Paragraph>
          Nous vous invitons à consulter régulièrement cette politique sur notre
          site internet. Elle pourra faire l&apos;objet de mises à jour.
        </Paragraph>
        <Paragraph>Mise à jour le 31/03/2023. </Paragraph>
      </>
    </>
  )
}

export default DataPrivacy
