import { Card, Col, message, Result, Steps, theme, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import {
  CreateCandidateData,
  defaultCreateCandidateData,
  RefStepInterface,
} from '../../models/FormDataModels'
import Generals from './steps/Generals'
import StepperNavigation from '../stepper-navigation/StepperNavigation'
import { useIntl } from 'react-intl'
import Experiences from './steps/Experiences'
import Availabilities from './steps/Availabilities'
import Files from './steps/Files'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { getJobs } from '../../reducers/JobReducer'
import { getExperienceLevels } from '../../reducers/ExperienceLevelReducer'
import { getDays } from '../../reducers/DayReducer'
import { getTimeSlots } from '../../reducers/TimeSlotsReducer'
import { createCandidate } from '../../reducers/CandidateReducer'

const { Title } = Typography

const CandidateFormWrapper = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [messageApi, contextHolder] = message.useMessage()

  const [formSubmitedWithSuccess, setFormSubmitedWithSuccess] =
    useState<boolean>(false)

  const [current, setCurrent] = useState<number>(0)
  const [nextIsLoading, setNextIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<CreateCandidateData>(
    defaultCreateCandidateData
  )

  const GeneralRef = useRef<RefStepInterface>(null)
  const ExperiencesRef = useRef<RefStepInterface>(null)
  const AvailabilitiesRef = useRef<RefStepInterface>(null)
  const FilesRef = useRef<RefStepInterface>(null)

  const updateData = (fieldsToUpdate: Partial<CreateCandidateData>) => {
    setData({ ...data, ...fieldsToUpdate })
  }

  // Error is the candidate creation error, if applicable
  const { error } = useAppSelector((state) => state.candidate)

  /**
   * Stepper setup
   */
  const steps = [
    {
      title: intl.formatMessage({ id: 'STEP.GENERAL.TITLE' }),
      content: (
        <Generals
          data={data}
          updateData={updateData}
          hasError={false}
          ref={GeneralRef}
        />
      ),
      ref: GeneralRef,
    },
    {
      title: intl.formatMessage({ id: 'STEP.EXPERIENCES.TITLE' }),
      description: intl.formatMessage({ id: 'STEP.EXPERIENCES.DESCRIPTION' }),
      content: (
        <Experiences
          data={data}
          updateData={updateData}
          hasError={false}
          ref={ExperiencesRef}
        />
      ),
      ref: ExperiencesRef,
    },
    {
      title: intl.formatMessage({ id: 'STEP.AVAILABILITIES.TITLE' }),
      description: intl.formatMessage({
        id: 'STEP.AVAILABILITIES.DESCRIPTION',
      }),
      content: (
        <Availabilities
          data={data}
          updateData={updateData}
          hasError={false}
          ref={AvailabilitiesRef}
        />
      ),
      ref: AvailabilitiesRef,
    },
    {
      title: intl.formatMessage({ id: 'STEP.FILES.TITLE' }),
      content: (
        <Files
          data={data}
          updateData={updateData}
          hasError={false}
          ref={FilesRef}
        />
      ),
      ref: FilesRef,
    },
  ]

  const next = () => setCurrent(current + 1)
  const prev = () => setCurrent(current - 1)

  /**
   * Previous button tapped callback
   * - Always move to previous step
   */
  const prevTapped = () => {
    window.scrollTo(0, 0)
    prev()
  }

  /**
   * Next button tapped callback
   * 1. Retrieve the ref to current step
   * 2. Invoke the steps `handleNext()`
   * 3. Then only if `canGoNext` is true pass to the next step
   */
  const nextTapped = () => {
    setNextIsLoading(true)
    steps[current].ref?.current?.handleNext().then((canGoNext) => {
      if (canGoNext) {
        window.scrollTo(0, 0)
        next()
      }
      setNextIsLoading(false)
    })
  }

  const doneTapped = () => {
    setNextIsLoading(true)
    // We still need to validate the last step
    steps[current].ref?.current?.handleNext().then((canGoNext) => {
      if (canGoNext) {
        window.scrollTo(0, 0)
        dispatch(createCandidate(data)).then(
          (result) => {
            setFormSubmitedWithSuccess(true)
          },
          (_reason) => {
            messageApi.open({
              type: 'error',
              content: String(error),
            })
            setNextIsLoading(false)
          }
        )
      }
    })
  }

  const stepHeader = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }))

  const { token } = theme.useToken()

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
  }

  useEffect(() => {
    dispatch(getJobs())
    dispatch(getExperienceLevels())
    dispatch(getDays())
    dispatch(getTimeSlots())
  }, [dispatch])

  return (
    <>
      {contextHolder}
      {!formSubmitedWithSuccess && (
        <>
          <Col xs={0} sm={24}>
            <Steps
              current={current}
              items={stepHeader}
              size="small"
              style={{ marginBottom: 28 }}
            />
          </Col>

          <Card
            style={contentStyle}
            actions={[
              <StepperNavigation
                key={-1}
                current={current}
                length={steps.length}
                nextIsLoading={nextIsLoading}
                prevTapped={prevTapped}
                nextTapped={nextTapped}
                doneTapped={doneTapped}
              />,
            ]}
          >
            {steps[current].description && (
              <Title level={3}>{steps[current].description}</Title>
            )}
            {steps[current].content}
          </Card>
        </>
      )}
      {formSubmitedWithSuccess && (
        <Card style={contentStyle}>
          <Result
            status="success"
            title={intl.formatMessage({
              id: 'CANDIDATE_FORM.SUCCESS.TITLE',
            })}
            subTitle={intl.formatMessage({
              id: 'CANDIDATE_FORM.SUCCESS.SUB_TITLE',
            })}
          />
        </Card>
      )}
    </>
  )
}

export default CandidateFormWrapper
