import { SyncOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Candidate, Day, TimeSlot } from '../../../models/User'
import { selectDays } from '../../../reducers/DayReducer'
import { useAppDispatch } from '../../../reducers/hooks'
import { selectTimeSlots } from '../../../reducers/TimeSlotsReducer'
import NestedTimeSlot from './NestedTimeSlot'

const AvailabiltiesNestedTable = () => {
  const dispatch = useAppDispatch()
  const days = useSelector(selectDays())
  const timeslots = useSelector(selectTimeSlots())

  const expandedRowRender = (record: Candidate) => {
    const columns: ColumnsType<TimeSlot> = [
      {
        //For the first column just display the timeSlot label without title
        title: '',
        key: '0',
        render: (timeslot: TimeSlot) => timeslot.label,
      },
      ...days.map((day: Day) => {
        // For the next columns display wether the candidate is available on the given timeSlot or not
        return {
          title: day.label,
          key: day.id,
          render: (timeslot: TimeSlot) => (
            <NestedTimeSlot candidate={record} day={day} timeslot={timeslot} />
          ),
        }
      }),
    ]

    return (
      <Table
        columns={columns}
        dataSource={timeslots}
        pagination={false}
        rowKey={(record) => record.id}
      />
    )
  }

  return {
    expandedRowRender,
    defaultExpandedRowKeys: ['0'],
    defaultExpandAllRows: false,
  }
}

export default AvailabiltiesNestedTable
