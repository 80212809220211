import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Button, Form, Input, Result, Space } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  deleteStatus,
  selectSelectedStatus,
} from '../../../reducers/StatusReducer'

interface Props {
  onFinish: () => void
}

const Delete = ({ onFinish }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [form] = Form.useForm()

  const status = useSelector(selectSelectedStatus())
  const { isLoading } = useAppSelector((state) => state.status)

  const onSubmit = () => {
    dispatch(deleteStatus(form.getFieldsValue())).then(() => {
      form.resetFields()
      onFinish()
    })
  }

  useEffect(() => {
    form.setFieldsValue(status)
  }, [status])

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Result
          title={intl.formatMessage({
            id: 'ADMIN.STATUS.FORM.DELETE.ARE_YOU_SURE',
          })}
          icon={<QuestionCircleOutlined style={{ color: 'orange' }} />}
        />
        <Form.Item
          name="id"
          required
          rules={[requiredRule(intl)]}
          className="d-none"
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {intl.formatMessage({ id: 'SUBMIT' })}
            </Button>
            <Button
              type="default"
              htmlType="reset"
              disabled={isLoading}
              onClick={() => onFinish()}
            >
              {intl.formatMessage({ id: 'CANCEL' })}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

export default Delete
