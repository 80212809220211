import { Select, Tag, message, Spin } from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Candidate } from '../../../models/User'
import { updateCandidateStatus } from '../../../reducers/CandidateReducer'
import { useAppDispatch } from '../../../reducers/hooks'
import { selectStatus } from '../../../reducers/StatusReducer'

const { Option } = Select

interface Props {
  candidate: Candidate
}

const StatusColumn = ({ candidate }: Props) => {
  const dispatch = useAppDispatch()
  const statuses = useSelector(selectStatus())

  const [messageApi, contextHolder] = message.useMessage()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <>
      {contextHolder}
      <Select
        value={candidate.status.id}
        style={{ width: 120 }}
        onChange={(value) => {
          setIsLoading(true)
          dispatch(
            updateCandidateStatus(
              candidate,
              statuses.filter((s) => s.id === value)[0]
            )
          )
            .then(() => setIsLoading(false))
            .catch(() => {
              setIsLoading(false)
              messageApi.open({
                type: 'error',
                content: String('Erreur'),
              })
            })
        }}
        loading={isLoading}
        disabled={isLoading}
      >
        {statuses.map((status) => (
          <Option key={status.id} value={status.id}>
            <Tag color={status.color} style={{ marginTop: 5, marginBottom: 5 }}>
              {status.name}
            </Tag>
          </Option>
        ))}
      </Select>
    </>
  )
}

export default StatusColumn
