import qs from 'qs'
import axios from 'axios'
import { Status } from '../models/User'
import { STATUS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../pages/admin/Candidates'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { act } from 'react-dom/test-utils'

// utils
// @types

// ----------------------------------------------------------------------
type State = {
  isLoading: boolean
  error: Error | string | null
  items: Status[]
  selectedId: number | undefined
  query: TableParams
  action: 'create' | 'update' | 'delete'
  modalIsOpen: boolean
}

const initialState: State = {
  isLoading: false,
  error: null,
  items: [],
  selectedId: undefined,
  action: 'create',
  modalIsOpen: false,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    setSelectedId(state, action) {
      state.selectedId = action.payload
    },

    setPagination(state, action) {
      const query = {
        ...state.query,
        pagination: { ...state.query.pagination, ...action.payload },
      }
      state.query = query
    },
    setQuery(state, action) {
      state.query = action.payload
    },

    openModalForAction(state, action) {
      state.action = action.payload
      state.modalIsOpen = true
    },
    closeModal(state) {
      state.modalIsOpen = false
    },

    // GET Items
    getItemsSuccess(state, action) {
      state.isLoading = false
      state.items = action.payload
    },
    updateItemsSuccess(state, action) {
      const next = [...state.items].map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              ...action.payload,
            }
          : item
      )
      state.isLoading = false
      state.items = next
    },
    deleteItemsSuccess(state, action) {
      const next = [...state.items].filter(
        (item) => item.id !== action.payload.id
      )
      state.isLoading = false
      state.items = next
    },
  },
})

// Reducer
export default slice.reducer

export const { setQuery, setSelectedId, openModalForAction, closeModal } =
  slice.actions

/**
 * Actions
 */
export function getStatus(params: TableParams) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios
      .get(`${STATUS_URL}?${qs.stringify(params)}`)
      .then((response) => {
        const data = response.data
        dispatch(slice.actions.getItemsSuccess(data.data))
        dispatch(
          slice.actions.setPagination({
            total: data.totalRecords,
            current: data.currentPage,
          })
        )
      })
  }
}

export function createStatus(status: Status) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.post(STATUS_URL, status).then((response) => {
      dispatch(slice.actions.stopLoading())
    })
  }
}

export function updateStatus(status: Status) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.put(`${STATUS_URL}/${status.id}`, status).then((response) => {
      dispatch(slice.actions.updateItemsSuccess(status))
    })
  }
}

export function deleteStatus(status: Status) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.delete(`${STATUS_URL}/${status.id}`).then((response) => {
      dispatch(slice.actions.deleteItemsSuccess(status))
    })
  }
}

export function deleteCanditateByStatus(status: Status) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    await axios
      .delete(`${STATUS_URL}/${status.id}/candidates`)
      .catch((error) => {
        throw error // Throw error again to allow execution of others catch()
      })
      .finally(() => dispatch(slice.actions.stopLoading()))
  }
}

/**
 * Selectors
 */

const selectRawItems = (state: RootState): Status[] => state.status.items
const selectRawSelectedId = (state: RootState): number | undefined =>
  state.status.selectedId

export const selectStatus = () =>
  createSelector<any, Status[]>([selectRawItems], (items: Status[]) => items)

export const selectStatusById = (id: number) =>
  createSelector([selectRawItems], (items: Status[]) =>
    items.find((e) => e.id === id)
  )

export const selectSelectedStatus = () =>
  createSelector<any, Status | undefined>(
    [selectRawItems, selectRawSelectedId],
    (items: Status[], selectedId: number | undefined) =>
      selectedId ? items.find((i) => i.id === selectedId) : undefined
  )
