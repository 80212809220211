import { Card, Form, Radio, Space, Switch, Typography } from 'antd'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ExperiencesData, InterestData } from '../../models/FormDataModels'
import { ExperienceLevel, Job } from '../../models/User'
import { selectExperienceLevels } from '../../reducers/ExperienceLevelReducer'
import { requiredRule } from '../../utils/rules'
import Loading from '../utils/Loading'

const { Text } = Typography

interface Props {
  job: Job
  interest: InterestData | undefined
}

const JobItem = ({ job, interest }: Props) => {
  const intl = useIntl()
  const levels = useSelector(selectExperienceLevels())

  const isOpen = () => interest && interest.isOpen

  return (
    <Card style={{ marginBottom: 20 }}>
      <Space
        direction="vertical"
        style={{ width: '100%' }}
        size={isOpen() ? 10 : 0}
      >
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Space direction="vertical">
            <Text style={{ fontWeight: 'bold' }}>{job.name}</Text>
            {!isOpen() && <Text>{job.description}</Text>}
          </Space>

          <Form.Item
            name={['interests', `${job.id}`, 'isOpen']}
            style={{ margin: 0 }}
          >
            <Switch checked={isOpen()} />
          </Form.Item>
        </Space>

        <Space
          direction="vertical"
          style={{
            display: isOpen() ? 'block' : 'none',
          }}
        >
          <Form.Item
            name={['interests', `${job.id}`, 'levelId']}
            label={intl.formatMessage({
              id: 'STEP.EXPERIENCES.FIELDS.ACTUAL_LEVEL',
            })}
            rules={isOpen() ? [requiredRule(intl)] : []}
            style={{ margin: 0 }}
          >
            {levels.length > 0 ? (
              <Radio.Group buttonStyle="solid">
                {levels.map((level) => (
                  <Radio.Button key={level.id} value={level.id}>
                    {level.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            ) : (
              <Loading></Loading>
            )}
          </Form.Item>
        </Space>
      </Space>
    </Card>
  )
}

export default JobItem
