const BASE_API_URL = process.env.REACT_APP_API_URL
const API_URL = BASE_API_URL + '/v1'

export const JOBS_URL = API_URL + '/jobs'
export const DAYS_URL = API_URL + '/days'
export const TIME_SLOTS_URL = API_URL + '/timeslots'
export const EXPERIENCE_LEVELS_URL = API_URL + '/experience-levels'

export const CANDIDATES_URL = API_URL + '/candidates'

const ADMIN_URL = API_URL + '/admins'
export const HOME_URL = ADMIN_URL + '/home'
export const STATUS_URL = ADMIN_URL + '/statuses'
export const ADMIN_CANDIDATES_URL = ADMIN_URL + '/candidates'

const ADMIN_AUTH_URL = ADMIN_URL + '/auth'
export const LOGIN_URL = ADMIN_AUTH_URL + '/login'
export const ME_INFOS_URL = ADMIN_AUTH_URL + '/me'
