import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Breadcrumb, Layout, Menu, Space, theme } from 'antd'
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { LayoutSider, siderCollapsedWidth, siderWidth } from './LayoutSider'
import RightHeader from './RightHeader'
import { LayoutFooter } from './LayoutFooter'
import { callMsGraph } from '../../utils/MsGraphApiCall'
import { useAppDispatch } from '../../reducers/hooks'
import { getUser, updateUserSuccess } from '../../reducers/UserReducer'
import { GraphData } from '../../models/GraphData'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from '../../authConfig'

const { Header, Content, Footer } = Layout

export const AdminLayout: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [graphData, setGraphData] = useState<GraphData | undefined>(undefined)

  const { result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  )

  useEffect(() => {
    if (!!graphData) {
      // We already have the data, no need to call the API
      return
    }

    if (!!error) {
      // Error occurred attempting to acquire a token, either handle the error or do nothing
      return
    }

    if (result) {
      dispatch(getUser())
      callMsGraph().then((response) => {
        setGraphData(response)
        dispatch(
          updateUserSuccess({
            id: response.id,
            firstName: response.givenName,
            lastName: response.surname,
            email: response.mail,
          })
        )
      })
    }
  }, [error, result, graphData])

  const { token } = theme.useToken()

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
  }
  return (
    <AuthenticatedTemplate>
      <Layout style={{ minHeight: '100vh' }}>
        <Header
          style={{ paddingLeft: 20, paddingRight: 20, background: '#fff' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: 0,
              height: '100%',
              alignItems: 'center',
            }}
          >
            <img
              alt="Paris Society Extra Recruiter"
              src="/assets/logo.png"
              style={{
                float: 'left',
                height: 46,
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
            />
            <Menu
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={['1']}
              items={[]}
            />
            <RightHeader />
          </div>
        </Header>
        <Layout>
          <LayoutSider />
          <Layout style={{ padding: '0 24px 24px', background: '#f8f8f2' }}>
            <Content style={contentStyle}>
              <Outlet />
            </Content>
            <LayoutFooter />
          </Layout>
        </Layout>
      </Layout>
    </AuthenticatedTemplate>
  )
}
