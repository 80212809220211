import { configureStore } from '@reduxjs/toolkit'
import CandidateReducer from './CandidateReducer'
import DayReducer from './DayReducer'
import ExperienceLevelReducer from './ExperienceLevelReducer'
import JobReducer from './JobReducer'
import StatusReducer from './StatusReducer'
import TimeSlotsReducer from './TimeSlotsReducer'
import UserReducer from './UserReducer'

export const store = configureStore({
  reducer: {
    user: UserReducer,
    job: JobReducer,
    experienceLevel: ExperienceLevelReducer,
    day: DayReducer,
    timeSlot: TimeSlotsReducer,
    candidate: CandidateReducer,
    status: StatusReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
