import { Button, Modal, Result } from 'antd'
import {
  deleteCanditate,
  selectCandidateById,
} from '../../../reducers/CandidateReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useIntl } from 'react-intl'

interface Props {
  id: number
}

const DeleteCandidate = ({ id }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState<boolean>(false)
  const candidate = useAppSelector(selectCandidateById(id))

  if (candidate === undefined) return <></>

  return (
    <>
      <Button
        icon={<DeleteOutlined />}
        danger
        onClick={() => setShowModal(true)}
      />
      <Modal
        title={intl.formatMessage({
          id: 'ADMIN.CANDIDATES.DELETE',
        })}
        open={showModal}
        okType="danger"
        okText={intl.formatMessage({
          id: 'ADMIN.CANDIDATES.DELETE',
        })}
        onOk={() => {
          dispatch(deleteCanditate(candidate)).then(() => setShowModal(false))
        }}
        onCancel={() => setShowModal(false)}
      >
        <Result
          title={intl.formatMessage({
            id: 'ADMIN.CANDIDATES.DELETE_CONFIRMATION',
          })}
          icon={<WarningOutlined style={{ color: '#dc4446' }} />}
        />
      </Modal>
    </>
  )
}

export default DeleteCandidate
