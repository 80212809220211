import { SyncOutlined } from '@ant-design/icons'
import { message, Tag } from 'antd'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Candidate, Day, TimeSlot } from '../../../models/User'
import { updateCandidateAvailability } from '../../../reducers/CandidateReducer'
import { useAppDispatch } from '../../../reducers/hooks'
import { isAvailableOn } from '../../../reducers/TimeSlotsReducer'

interface Props {
  candidate: Candidate
  day: Day
  timeslot: TimeSlot
}

const NestedTimeSlot = ({ candidate, day, timeslot }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [messageApi, contextHolder] = message.useMessage()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onClick = () => {
    setIsLoading(true)
    dispatch(updateCandidateAvailability(candidate, day, timeslot))
      .then(() => setIsLoading(false))
      .catch(() => {
        setIsLoading(false)
        messageApi.open({
          type: 'error',
          content: String('Erreur'),
        })
      })
  }

  const isAvailable = isAvailableOn(candidate.availabilities, day, timeslot)

  return (
    <>
      {contextHolder}
      <Tag
        color={isLoading ? 'grey' : isAvailable ? 'green' : 'red'}
        icon={isLoading ? <SyncOutlined spin /> : null}
        onClick={() => (!isLoading ? onClick() : {})}
        style={{ cursor: 'pointer' }}
      >
        {isAvailable
          ? intl.formatMessage({ id: 'ADMIN.TABLE.NESTED.AVAILABLE' })
          : intl.formatMessage({ id: 'ADMIN.TABLE.NESTED.NOT_AVAILABLE' })}
      </Tag>
    </>
  )
}

export default NestedTimeSlot
