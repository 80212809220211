import React from 'react'
import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import App from '../App'
import Profile from '../pages/Profile'
import { AdminLayout } from '../components/layout/AdminLayout'
import { AuthLayout } from '../components/layout/auth/AuthLayout'
import { useIsAuthenticated } from '@azure/msal-react'
import { Login } from '../pages/Login'
import LoginRedirect from '../pages/LoginRedirect'
import Home from '../pages/admin/Home'
import CandidateForm from '../pages/CandidateForm'
import { PublicLayout } from '../components/layout/PublicLayout'
import Candidates from '../pages/admin/Candidates'
import CandidateDetails from '../pages/admin/CandidateDetails'
import LegalNotice from '../pages/legacy/LegalNotice'
import DataPrivacy from '../pages/legacy/DataPrivacy'
import StatusManager from '../pages/admin/StatusManager'

const AppRoutes: FC = () => {
  const isAuthenticated = useIsAuthenticated()

  return (
    <Routes>
      <Route element={<App />}>
        {/* MSAL Login redirect route */}
        <Route element={<AuthLayout />}>
          <Route path="/login-redirect" element={<LoginRedirect />} />
        </Route>

        <Route element={<AdminLayout />}>
          <Route path="/admin" element={<Home />} />
          <Route
            path="/admin/candidates/:candidate"
            element={<CandidateDetails />}
          />
          <Route path="/admin/candidates" element={<Candidates />} />
          <Route path="/admin/status" element={<StatusManager />} />
          <Route path="/admin/*" element={<Navigate to="/admin" />} />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<PublicLayout />}>
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/data-privacy" element={<DataPrivacy />} />
          <Route path="/" element={<CandidateForm />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}

export { AppRoutes }
