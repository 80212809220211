import { Checkbox, Form, Input, Space, Typography } from 'antd'
import { forwardRef, Ref, useImperativeHandle, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  AvailabilitiesData,
  RefStepInterface,
  StepProps,
} from '../../../models/FormDataModels'
import { getDays, selectDays } from '../../../reducers/DayReducer'
import {
  getTimeSlots,
  selectTimeSlots,
} from '../../../reducers/TimeSlotsReducer'
import { stringRule } from '../../../utils/rules'
import Loading from '../../utils/Loading'
import { useAppDispatch } from '../../../reducers/hooks'
import MissingData from '../../utils/MissingData'
const { TextArea } = Input

const Availabilities = forwardRef(
  ({ data, updateData, hasError }: StepProps, ref: Ref<RefStepInterface>) => {
    useImperativeHandle(ref, () => ({ handleNext }))

    const intl = useIntl()
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    async function handleNext() {
      return await form
        .validateFields()
        .then(async (result) => {
          //TODO: Require at least one timeslot to be ticked
          return true
        })
        .catch((err) => {
          return false
        })
    }

    const updateAvailabilitiesData = (partial: Partial<AvailabilitiesData>) =>
      updateData({
        availabilities: {
          ...data.availabilities,
          ...partial,
        },
      })

    const days = useSelector(selectDays())
    const timeSlots = useSelector(selectTimeSlots())

    const fetchMissingData = () => {
      setIsLoading(true)
      Promise.all([dispatch(getDays()), dispatch(getTimeSlots())]).then(
        (values) => setIsLoading(false)
      )
    }

    return days.length > 0 && timeSlots.length > 0 ? (
      <Form
        labelCol={{ span: 4 }}
        layout="vertical"
        form={form}
        onValuesChange={(_, allFields) => {
          updateAvailabilitiesData(allFields)
        }}
        initialValues={data.availabilities}
      >
        {days.map((day) => (
          <Form.Item
            name={['availabilities', `${day.id}`, 'timeSlotIds']}
            key={day.id}
            label={day.label}
            style={{ fontWeight: 'bold' }}
          >
            {timeSlots.length > 0 ? (
              <Checkbox.Group>
                {timeSlots.map((timeSlot) => (
                  <Checkbox
                    style={{ fontWeight: '400' }}
                    key={timeSlot.id}
                    value={timeSlot.id}
                  >
                    {timeSlot.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            ) : (
              <Loading></Loading>
            )}
          </Form.Item>
        ))}

        <Form.Item
          label={intl.formatMessage({ id: 'MORE_INFOS' })}
          rules={[stringRule(intl)]}
          name={'comment'}
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    ) : (
      <MissingData onClick={fetchMissingData} loading={isLoading} />
    )
  }
)

Availabilities.displayName = 'Availabilities'

export default Availabilities
