import { forwardRef, Ref, useImperativeHandle } from 'react'
import { Form, Input, Row, Col, Checkbox } from 'antd'
import {
  GeneralData,
  RefStepInterface,
  StepProps,
} from '../../../models/FormDataModels'
import { useIntl } from 'react-intl'
import {
  emailRule,
  haveMajorityRule,
  requiredRule,
  stringRule,
  validPhoneNumberRule,
} from '../../../utils/rules'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const Generals = forwardRef(
  ({ data, updateData, hasError }: StepProps, ref: Ref<RefStepInterface>) => {
    useImperativeHandle(ref, () => ({ handleNext }))

    async function handleNext() {
      return await form
        .validateFields()
        .then(async (result) => {
          return true
        })
        .catch((err) => {
          return false
        })
    }

    const intl = useIntl()
    const [form] = Form.useForm()

    const updateGeneralData = (partial: Partial<GeneralData>) =>
      updateData({
        general: {
          ...data.general,
          ...partial,
        },
      })

    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={data.general}
        onValuesChange={(_, allFields) => updateGeneralData(allFields)}
      >
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item
              rules={[stringRule(intl), requiredRule(intl)]}
              label={intl.formatMessage({ id: 'FIRST_NAME' })}
              name="firstName"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              rules={[stringRule(intl), requiredRule(intl)]}
              label={intl.formatMessage({ id: 'LAST_NAME' })}
              name="lastName"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={8} xs={24}>
            <Form.Item
              rules={[emailRule(intl), requiredRule(intl)]}
              label={intl.formatMessage({ id: 'EMAIL' })}
              name="email"
            >
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              rules={[requiredRule(intl), validPhoneNumberRule(intl)]}
              label={intl.formatMessage({ id: 'PHONE' })}
              name="phone"
            >
              <PhoneInput defaultCountry="FR" onChange={() => {}} />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              rules={[requiredRule(intl), haveMajorityRule(intl)]}
              label={intl.formatMessage({ id: 'STEP.GENERAL.FIELDS.MAJORITY' })}
              name="majority"
              valuePropName="checked"
            >
              <Checkbox>
                {intl.formatMessage({
                  id: 'STEP.GENERAL.FIELDS.MAJORITY_DESCRIPTION',
                })}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
)

Generals.displayName = 'Generals'

export default Generals
