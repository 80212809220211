import qs from 'qs'
import axios from 'axios'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../pages/admin/Candidates'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { CreateCandidateData } from '../models/FormDataModels'
import { Candidate, Day, Status, TimeSlot } from '../models/User'
import { ADMIN_CANDIDATES_URL, CANDIDATES_URL } from '../utils/urls'

// utils
// @types

// ----------------------------------------------------------------------
type State = {
  isLoading: boolean
  error: Error | string | null
  items: Candidate[]
  query: TableParams
}

const initialState: State = {
  isLoading: false,
  error: null,
  items: [],
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Items
    getItemsSuccess(state, action) {
      state.isLoading = false
      state.items = action.payload
    },
    getItemSuccess(state, action) {
      state.isLoading = false
      state.items = [...state.items, action.payload]
    },
    setPagination(state, action) {
      const query = {
        ...state.query,
        pagination: { ...state.query.pagination, ...action.payload },
      }
      state.query = query
    },
    setQuery(state, action) {
      state.query = action.payload
    },
    updateCandidateSuccess(state, action) {
      state.isLoading = false
      state.items = state.items.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }
        return item
      })
    },
    deleteCanditateSuccess(state, action) {
      state.items = state.items.filter((item) => item.id != action.payload.id)
    },
  },
})

// Reducer
export default slice.reducer

export const { setQuery } = slice.actions

/**
 * Actions
 */
export function getCandidates(params: TableParams) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios
      .get(`${ADMIN_CANDIDATES_URL}?${qs.stringify(params)}`)
      .then((response) => {
        const data = response.data
        dispatch(slice.actions.getItemsSuccess(data.data))
        dispatch(
          slice.actions.setPagination({
            total: data.totalRecords,
            current: data.currentPage,
          })
        )
      })
  }
}

export function createCandidate(data: CreateCandidateData) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    const candidateToSend = new FormData()

    // General data doesn't need special treatement ...
    for (const [key, value] of Object.entries(data.general)) {
      candidateToSend.append(key, value)
    }

    // Experiences
    candidateToSend.append('experiencesComment', data.experiences.comment ?? '')
    Object.keys(data.experiences.interests).forEach((jobId: any) => {
      if (!data.experiences.interests[jobId].levelId) {
        return
      }
      candidateToSend.append(`experiences[${jobId}][job]`, jobId)
      candidateToSend.append(
        `experiences[${jobId}][level]`,
        data.experiences.interests[jobId].levelId.toString()
      )
    })

    // Availabilities
    candidateToSend.append(
      'availabilitiesComment',
      data.availabilities.comment ?? ''
    )
    Object.keys(data.availabilities.availabilities).forEach((dayId: any) => {
      if (!data.availabilities.availabilities[dayId].timeSlotIds) {
        return
      }
      candidateToSend.append(`availabilities[${dayId}][day]`, dayId)
      data.availabilities.availabilities[dayId].timeSlotIds.forEach(
        (timeSlotId) =>
          candidateToSend.append(
            `availabilities[${dayId}][timeslots][]`,
            timeSlotId.toString()
          )
      )
    })

    // Documents
    if (
      data.files.cv &&
      data.files.cv.hasOwnProperty('fileList') &&
      data.files.cv.fileList.length === 1
    ) {
      candidateToSend.append('cv', data.files.cv.fileList[0].originFileObj)
    }
    if (
      data.files.video &&
      data.files.video.hasOwnProperty('fileList') &&
      data.files.video.fileList.length === 1
    ) {
      candidateToSend.append(
        'video',
        data.files.video.fileList[0].originFileObj
      )
    }

    return await axios
      .post(CANDIDATES_URL, candidateToSend)
      .then((_response) => {
        dispatch(slice.actions.stopLoading())
        return Promise.resolve()
      })
      .catch((_error) => {
        dispatch(slice.actions.hasError('Erreur lors de la création'))
        return Promise.reject()
      })
  }
}

export function updateCandidateStatus(candidate: Candidate, status: Status) {
  return async (dispatch: AppDispatch) => {
    const oldCandidate = Object.assign({}, candidate)
    dispatch(
      slice.actions.updateCandidateSuccess({ ...candidate, status: status })
    )
    await axios
      .put(`${ADMIN_CANDIDATES_URL}/${candidate.id}/status`, {
        status: status.id,
      })
      .catch((error) => {
        dispatch(slice.actions.updateCandidateSuccess(oldCandidate))
        throw error // Throw error again to allow execution of others catch()
      })
  }
}

export function updateCandidateAvailability(
  candidate: Candidate,
  day: Day,
  timeslot: TimeSlot
) {
  return async (dispatch: AppDispatch) => {
    /**
     * Known issue with the oldCandidate
     * oldCandiate is a snapshot of the candidate at the time when the request is initiated
     * In case multiple requests are started at the same time, multiples snapshot are made
     * So when updating the store some old availabilites might be displayed
     */
    const oldCandidate = Object.assign({}, candidate)

    const newAvailabilites = () => {
      if (!candidate.availabilities.map((a) => a.day.id).includes(day.id)) {
        // We need to add the day and the timeSlot
        return [
          ...candidate.availabilities,
          { day: day, timeSlots: [timeslot] },
        ]
      }

      // Else we modify the day
      return candidate.availabilities.map((a) => {
        if (a.day.id !== day.id) {
          return a
        }
        if (a.timeSlots.map((timeSlot) => timeSlot.id).includes(timeslot.id)) {
          // If available we need to remove the availability
          return {
            ...a,
            timeSlots: a.timeSlots.filter((ts) => ts.id !== timeslot.id),
          }
        } else {
          // In not available we need to add the availability
          return {
            ...a,
            timeSlots: [...a.timeSlots, timeslot],
          }
        }
      })
    }

    dispatch(
      slice.actions.updateCandidateSuccess({
        ...candidate,
        availabilities: newAvailabilites(),
      })
    )
    await axios
      .put(`${ADMIN_CANDIDATES_URL}/${candidate.id}/availabilities`, {
        day: day.id,
        timeslot: timeslot.id,
      })
      .catch((error) => {
        dispatch(slice.actions.updateCandidateSuccess(oldCandidate))
        throw error // Throw error again to allow execution of others catch()
      })
  }
}

export function deleteCanditate(candidate: Candidate) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())

    const candidateToDelete = Object.assign(candidate, {})
    dispatch(slice.actions.deleteCanditateSuccess(candidate))
    await axios
      .delete(`${ADMIN_CANDIDATES_URL}/${candidate.id}`)
      .catch((error) => {
        dispatch(slice.actions.getItemSuccess(candidateToDelete))
        throw error // Throw error again to allow execution of others catch()
      })
      .finally(() => dispatch(slice.actions.stopLoading()))
  }
}

/**
 * Selectors
 */

const selectRawItems = (state: RootState): Candidate[] => state.candidate.items

export const selectCandidates = () =>
  createSelector<any, Candidate[]>(
    [selectRawItems],
    (items: Candidate[]) => items
  )

export const selectCandidateById = (id: number) =>
  createSelector([selectRawItems], (items: Candidate[]) =>
    items.find((e) => e.id === id)
  )
