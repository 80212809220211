import { Rule } from 'antd/es/form'
import { IntlShape } from 'react-intl'
import dayjs, { Dayjs } from 'dayjs'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const integerRule: Rule = {
  type: 'integer',
  message: 'The input is not valid number',
}

export const numberRule = function (intl: IntlShape): Rule {
  return {
    type: 'number',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_NUMBER' }),
  }
}

export const stringRule = function (intl: IntlShape): Rule {
  return {
    type: 'string',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_STRING' }),
  }
}

export const urlRule = function (intl: IntlShape): Rule {
  return {
    type: 'url',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_URL' }),
  }
}

export const emailRule = function (intl: IntlShape): Rule {
  return {
    type: 'email',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_EMAIL' }),
  }
}

export const requiredRule = function (intl: IntlShape): Rule {
  return {
    required: true,
    message: intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }),
  }
}

export const acceptedRule = function (intl: IntlShape): Rule {
  return {
    validator(_, value) {
      if (value) {
        return Promise.resolve()
      }
      return Promise.reject(intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }))
    },
  }
}

export const validPhoneNumberRule = function (intl: IntlShape): Rule {
  return ({ getFieldValue }) => ({
    validator(_, value: any) {
      return value && isValidPhoneNumber(value)
        ? Promise.resolve()
        : Promise.reject(
            new Error(intl.formatMessage({ id: 'FIELD.ERROR.NOT_VALID_PHONE' }))
          )
    },
  })
}

export const haveMajorityRule = function (intl: IntlShape): Rule {
  return ({ getFieldValue }) => ({
    validator(_, value: boolean) {
      return !value
        ? Promise.reject(
            new Error(intl.formatMessage({ id: 'FIELD.ERROR.NOT_MAJOR' }))
          )
        : Promise.resolve()
    },
  })
}

export const dateMajorRule = function (intl: IntlShape): Rule {
  return ({ getFieldValue }) => ({
    validator(_, value: Dayjs | null) {
      if (!value) {
        return Promise.reject(
          new Error(intl.formatMessage({ id: 'FIELD.ERROR.NOT_MAJOR' }))
        )
      }

      // This is the date when the user has the minimum accepted age
      const majorityDate = value.add(18, 'years')

      return majorityDate < dayjs()
        ? Promise.resolve()
        : Promise.reject(
            new Error(intl.formatMessage({ id: 'FIELD.ERROR.NOT_MAJOR' }))
          )
    },
  })
}
