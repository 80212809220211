import { createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { Availabilty, Day, TimeSlot } from '../models/User'
import { DAYS_URL, TIME_SLOTS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'

// utils
// @types

// ----------------------------------------------------------------------
type State = {
  isLoading: boolean
  error: Error | string | null
  items: TimeSlot[]
}

const initialState: State = {
  isLoading: false,
  error: null,
  items: [],
}

const slice = createSlice({
  name: 'timeSlot',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Admin
    getItemsSuccess(state, action) {
      state.isLoading = false
      state.items = action.payload
    },
  },
})

// Reducer
export default slice.reducer

/**
 * Actions
 */
export function getTimeSlots() {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading())
    await axios.get(TIME_SLOTS_URL).then((response) => {
      const items = response.data.data
      dispatch(slice.actions.getItemsSuccess(items))
    })
  }
}

export function isAvailableOn(
  availabilities: Availabilty[],
  day: Day,
  timeslot: TimeSlot
) {
  return (
    availabilities.filter(
      (availability) =>
        availability.day.id === day.id &&
        availability.timeSlots
          .map((timeSlot) => timeSlot.id)
          .includes(timeslot.id)
    ).length > 0
  )
}

/**
 * Selectors
 */

const selectRawItems = (state: RootState): TimeSlot[] => state.timeSlot.items

export const selectTimeSlots = () =>
  createSelector<any, TimeSlot[]>(
    [selectRawItems],
    (items: TimeSlot[]) => items
  )
