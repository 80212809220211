import { Modal } from 'antd'
import Create from './form/Create'
import Update from './form/Update'
import Delete from './form/Delete'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { closeModal } from '../../reducers/StatusReducer'

interface Props {}

const StatusModal = ({}: Props) => {
  const dispatch = useAppDispatch()

  const { modalIsOpen, action } = useAppSelector((state) => state.status)

  const handleClose = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <Modal open={modalIsOpen} onCancel={handleClose} footer={[]}>
        {action === 'create' && <Create onFinish={handleClose} />}
        {action === 'update' && <Update onFinish={handleClose} />}
        {action === 'delete' && <Delete onFinish={handleClose} />}
      </Modal>
    </>
  )
}

export default StatusModal
