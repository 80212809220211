import { Outlet } from 'react-router-dom'
import { Col, Layout, MenuProps, Row, theme } from 'antd'
import { LayoutFooter } from '../LayoutFooter'

const { Content } = Layout

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

export const AuthLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout className="site-layout">
        <Content
          style={{
            margin: '0',
            backgroundImage: "url('assets/login_background.png')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Row
            justify="center"
            style={{
              padding: 0,
              margin: 0,
              height: '100vh',
            }}
          >
            <Col
              xxl={8}
              xl={10}
              lg={12}
              md={14}
              xs={24}
              style={{
                justifyContent: 'center',
                alignSelf: 'center',
                textAlign: 'center',
                background: '#f8f8f2',
              }}
            >
              <Outlet />
            </Col>
          </Row>
        </Content>
        <LayoutFooter />
      </Layout>
    </Layout>
  )
}
