import { forwardRef, Ref, useImperativeHandle } from 'react'
import {
  FilesData,
  RefStepInterface,
  StepProps,
} from '../../../models/FormDataModels'
import { Form, Row, Col, Upload, Checkbox, Typography, Space } from 'antd'
import { useIntl } from 'react-intl'
import { InboxOutlined } from '@ant-design/icons'
import { acceptedRule, requiredRule } from '../../../utils/rules'
import { Link } from 'react-router-dom'
import type { UploadProps } from 'antd'

const { Dragger } = Upload
const { Text } = Typography

const Files = forwardRef(
  ({ data, updateData, hasError }: StepProps, ref: Ref<RefStepInterface>) => {
    useImperativeHandle(ref, () => ({ handleNext }))

    async function handleNext() {
      return await form
        .validateFields()
        .then(async (result) => {
          return true
        })
        .catch((err) => {
          return false
        })
    }

    const intl = useIntl()
    const [form] = Form.useForm()

    const updateFilesData = (partial: Partial<FilesData>) =>
      updateData({
        files: {
          ...data.files,
          ...partial,
        },
      })

    const props: UploadProps = {
      maxCount: 1,
      multiple: false,
      beforeUpload(file) {
        return false
      },
    }

    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={data.general}
        onValuesChange={(_, allFields) => {
          updateFilesData(allFields)
        }}
      >
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item name="cv" rules={[requiredRule(intl)]}>
              <Dragger {...props} accept="application/pdf">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {intl.formatMessage({ id: 'STEP.FILES.CV' })}
                </p>
                <Text>
                  {intl.formatMessage({ id: 'STEP.FILES.CV.SUB_TITLE' })}
                </Text>
                <p className="ant-upload-hint">
                  {intl.formatMessage({ id: 'STEP.FILES.CV_DESCRIPTION' })}
                </p>
              </Dragger>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item name="video">
              <Dragger {...props} accept="video/*">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {intl.formatMessage({ id: 'STEP.FILES.VIDEO' })}
                </p>
                <Text>
                  {intl.formatMessage({ id: 'STEP.FILES.VIDEO.SUB_TITLE' })}
                </Text>
                <p className="ant-upload-hint">
                  {intl.formatMessage({ id: 'STEP.FILES.VIDEO_DESCRIPTION' })}
                </p>
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={24}>
            <Form.Item
              rules={[acceptedRule(intl)]}
              name="rgpd"
              valuePropName="checked"
            >
              <Checkbox>
                <>
                  {intl.formatMessage(
                    {
                      id: 'STEP.FILES.RGPD_AGREMENT.TITLE',
                    },
                    {
                      ici: (
                        <Link
                          to={'/data-privacy'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ici
                        </Link>
                      ),
                    }
                  )}
                </>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
)

Files.displayName = 'Files'

export default Files
