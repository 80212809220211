import { Button, Modal, Result, Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { UsergroupDeleteOutlined, WarningOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  deleteCanditateByStatus,
  selectStatusById,
} from '../../reducers/StatusReducer'

interface Props {
  id: number
  onSuccess: () => void
}

const DeleteCandidatesByStatus = ({ id, onSuccess }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState<boolean>(false)
  const status = useAppSelector(selectStatusById(id))

  if (status === undefined) return <></>

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: 'ADMIN.STATUS.DELETE_ALL_CANDIDATES',
        })}
      >
        <Button
          danger
          icon={<UsergroupDeleteOutlined />}
          onClick={() => setShowModal(true)}
        />
      </Tooltip>

      <Modal
        title={intl.formatMessage({
          id: 'ADMIN.STATUS.DELETE_ALL_CANDIDATES',
        })}
        open={showModal}
        okType="danger"
        okText={intl.formatMessage({
          id: 'ADMIN.CANDIDATES.DELETE',
        })}
        onOk={() => {
          dispatch(deleteCanditateByStatus(status)).then(() => {
            setShowModal(false)
            onSuccess()
          })
        }}
        onCancel={() => setShowModal(false)}
      >
        <Result
          title={intl.formatMessage({
            id: 'ADMIN.STATUS.DELETE_ALL_CANDIDATES_CONFIRMATION',
          })}
          icon={<WarningOutlined style={{ color: '#dc4446' }} />}
        />
      </Modal>
    </>
  )
}

export default DeleteCandidatesByStatus
