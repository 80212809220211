import React, { useState } from 'react'
import { Layout, Menu, theme } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {
  HomeOutlined,
  SettingOutlined,
  SolutionOutlined,
} from '@ant-design/icons'

const { Sider } = Layout

export const siderWidth = 300
export const siderCollapsedWidth = 80

interface Props {}

export const LayoutSider = ({}: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [collapsed, setCollapsed] = useState<boolean>(false)

  const { token } = theme.useToken()

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: '#fff',
    paddingTop: 10,
  }

  return (
    <Sider collapsed={true} style={contentStyle}>
      <Menu
        style={contentStyle}
        defaultSelectedKeys={['1']}
        mode="inline"
        items={[
          {
            key: '1',
            icon: <HomeOutlined />,
            label: intl.formatMessage({ id: 'ADMIN.MENU.HOME' }),
            onClick: () => navigate('/admin'),
          },
          {
            key: '2',
            icon: <SolutionOutlined />,
            label: intl.formatMessage({ id: 'ADMIN.MENU.CANDIDATES' }),
            onClick: () => navigate('/admin/candidates'),
          },
          {
            key: '3',
            icon: <SettingOutlined />,
            label: intl.formatMessage({ id: 'ADMIN.MENU.STATUS' }),
            onClick: () => navigate('/admin/status'),
          },
        ]}
      />
    </Sider>
  )
}
