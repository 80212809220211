import { Avatar, Button, Modal, QRCode, Space, Tag, Typography } from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Availabilty, Candidate } from '../../../models/User'
import { selectDays } from '../../../reducers/DayReducer'

const { Text, Title } = Typography
interface Props {
  candidate: Candidate
}

const AvatarColumn = ({ candidate }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Space direction="horizontal" size={20}>
        <Space>
          <Avatar
            style={{
              color: '#fff',
              backgroundColor: '#6c7cff',
              cursor: 'pointer',
            }}
          >
            {candidate && candidate.firstName && candidate.lastName
              ? candidate!.firstName.slice(0, 1) +
                candidate!.lastName.slice(0, 1)
              : '--'}
          </Avatar>
        </Space>
        <Space direction="vertical" size={0}>
          <Text>
            {candidate.firstName} {candidate.lastName}
          </Text>
          <Link to={`mailto:${candidate.email}`}>
            <Text style={{ fontWeight: 300 }}> {candidate.email}</Text>
          </Link>

          <Text
            onClick={showModal}
            style={{ fontWeight: 300, cursor: 'pointer' }}
          >
            {candidate.phone}
          </Text>
        </Space>
      </Space>
      <Modal
        title="Scan to call"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key={1} onClick={handleOk}>
            Close
          </Button>,
        ]}
      >
        <QRCode value={`tel:${candidate.phone}`} style={{ margin: 'auto' }} />
      </Modal>
    </>
  )
}

export default AvatarColumn
