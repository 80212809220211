import { Select, Form, Tag } from 'antd'
import { Rule } from 'antd/es/form'

interface Props {
  name: string
  label?: string
  rules?: Rule[]
}

const { Option } = Select

const ColorSelect = ({ name, label, rules }: Props) => {
  const items = [
    { id: 'pink', color: 'pink' },
    { id: 'red', color: 'red' },
    { id: 'yellow', color: 'yellow' },
    { id: 'orange', color: 'orange' },
    { id: 'cyan', color: 'cyan' },
    { id: 'green', color: 'green' },
    { id: 'blue', color: 'blue' },
    { id: 'purple', color: 'purple' },
    { id: 'geekblue', color: 'geekblue' },
    { id: 'magenta', color: 'magenta' },
    { id: 'volcano', color: 'volcano' },
    { id: 'gold', color: 'gold' },
    { id: 'lime', color: 'lime' },
  ]
  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select showArrow={true}>
        {items.map((item) => (
          <Option key={item.id} value={item.id}>
            <Tag
              style={{ display: 'inline', padding: '2px 8px' }}
              color={item.color}
            >
              {item.color}
            </Tag>
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default ColorSelect
