import { Dayjs } from 'dayjs'

export interface GeneralData {
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  phone: string | undefined
  majority: boolean
}

export interface ExperiencesData {
  interests: InterestData[]
  comment: string | undefined
}

export interface InterestData {
  isOpen: boolean
  jobId: number
  levelId: number
}

export interface AvailabilitiesData {
  availabilities: AvailabilyData[]
  comment: string | undefined
}

export interface AvailabilyData {
  dayId: number
  timeSlotIds: number[]
}

export interface FilesData {
  cv: any | undefined
  video: any | undefined
}

export interface CreateCandidateData {
  general: GeneralData
  experiences: ExperiencesData
  availabilities: AvailabilitiesData
  files: FilesData
}

export const defaultCreateCandidateData: CreateCandidateData = {
  general: {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    majority: false,
  },
  experiences: {
    interests: [],
    comment: undefined,
  },
  availabilities: {
    availabilities: [],
    comment: undefined,
  },
  files: {
    cv: undefined,
    video: undefined,
  },
}

export type StepProps = {
  data: CreateCandidateData
  updateData: (fieldsToUpdate: Partial<CreateCandidateData>) => void
  hasError: boolean
}

export interface RefStepInterface {
  handleNext: () => Promise<boolean>
}
