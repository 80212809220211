import { Avatar, Dropdown, MenuProps, Space, Typography } from 'antd'
import { useAppDispatch } from '../../reducers/hooks'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { BrowserUtils } from '@azure/msal-browser'
import { useSelector } from 'react-redux'
import Restricted from '../restricted/Restricted'
import { selectUser } from '../../reducers/UserReducer'
import { useIntl } from 'react-intl'

const Text = Typography

const RightHeader = () => {
  //   const navigate = useNavigate()
  const intl = useIntl()

  const dispatch = useAppDispatch()
  const { instance } = useMsal()

  const user = useSelector(selectUser)

  const items: MenuProps['items'] = [
    // {
    //   key: 1,
    //   label: <Text>Account</Text>,
    //   onClick: () => navigate('/profile'),
    // },
    {
      key: 2,
      label: <Text>{intl.formatMessage({ id: 'LOGOUT' })}</Text>,
      onClick: () =>
        instance.logoutRedirect({
          account: instance.getActiveAccount(),
          onRedirectNavigate: () => !BrowserUtils.isInIframe(),
        }),
    },
  ]

  return (
    <Dropdown menu={{ items }} placement="bottomLeft" arrow>
      <Avatar
        style={{
          color: '#fff',
          backgroundColor: '#6c7cff',
          cursor: 'pointer',
        }}
      >
        {user && user.firstName && user.lastName
          ? user!.firstName.slice(0, 1) + user!.lastName.slice(0, 1)
          : '--'}
      </Avatar>
    </Dropdown>
  )
}

export default RightHeader
