import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Button, Layout, Space, theme } from 'antd'
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsalAuthentication,
} from '@azure/msal-react'
import { LayoutSider, siderCollapsedWidth, siderWidth } from './LayoutSider'
import RightHeader from './RightHeader'
import { LayoutFooter } from './LayoutFooter'
import { callMsGraph } from '../../utils/MsGraphApiCall'
import { useAppDispatch } from '../../reducers/hooks'
import { getUser, updateUserSuccess } from '../../reducers/UserReducer'
import { GraphData } from '../../models/GraphData'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from '../../authConfig'

const { Header, Content, Footer } = Layout

export const PublicLayout: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isAuthenticated = useIsAuthenticated()

  return (
    <Layout hasSider style={{ minHeight: '100vh' }}>
      <Layout style={{ background: '#f8f8f2' }}>
        <Header
          style={{ paddingLeft: 20, paddingRight: 20, background: '#fff' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <img
              alt="Logo"
              src="/assets/logo.png"
              style={{
                float: 'left',
                height: 46,
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
            />
            {isAuthenticated && (
              <Button type="primary" onClick={() => navigate('/admin')}>
                Back-Office
              </Button>
            )}
          </div>
        </Header>

        <Content
          style={{
            margin: '0',
            paddingTop: 30,
            background: '#f8f8f2',
            maxWidth: '1000px',
            width: '100%',
            paddingRight: 10,
            paddingLeft: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <Outlet />
        </Content>

        <LayoutFooter />
      </Layout>
    </Layout>
  )
}
