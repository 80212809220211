import { FilePdfOutlined } from '@ant-design/icons'
import {
  Breadcrumb,
  Button,
  Card,
  InputRef,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import AvailabiltiesNestedTable from '../../components/admin/candidates-list/AvailabiltiesNestedTable'
import AvailabiltyColumn from '../../components/admin/candidates-list/AvailabiltyColumn'
import AvatarColumn from '../../components/admin/candidates-list/AvatarColumn'
import getColumnSearchProps, {
  DataIndex,
} from '../../components/admin/candidates-list/getColumnSearchProps'
import StatusColumn from '../../components/admin/candidates-list/StatusColumn'
import { Candidate } from '../../models/User'
import {
  getCandidates,
  selectCandidates,
  setQuery,
} from '../../reducers/CandidateReducer'
import { getDays, selectDays } from '../../reducers/DayReducer'
import {
  getExperienceLevels,
  selectExperienceLevels,
} from '../../reducers/ExperienceLevelReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { getJobs, selectJobs } from '../../reducers/JobReducer'
import { selectStatus } from '../../reducers/StatusReducer'
import { getStatus } from '../../reducers/StatusReducer'
import { getTimeSlots, selectTimeSlots } from '../../reducers/TimeSlotsReducer'
import DeleteCandidate from '../../components/admin/candidates-list/DeleteCandidate'

const { Text } = Typography

export interface TableParams {
  pagination?: TablePaginationConfig
  sortField?: string
  sortOrder?: string
  filters?: Record<string, FilterValue | null>
  search?: Record<string, string | null>
}

const Candidates = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)

  const jobs = useSelector(selectJobs())
  const days = useSelector(selectDays())
  const statuses = useSelector(selectStatus())
  const timeSlots = useSelector(selectTimeSlots())
  const candidates = useSelector(selectCandidates())
  const levels = useSelector(selectExperienceLevels())

  const { query: statusQuery } = useAppSelector((state) => state.status)
  const { isLoading, query: candidateQuery } = useAppSelector(
    (state) => state.candidate
  )

  const COMMENTS_WIDTH = 300

  const handleSearch = (selectedKeys: string[], dataIndex: DataIndex) => {
    const newQuery = {
      ...candidateQuery,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setQuery(newQuery))
    dispatch(getCandidates(newQuery))
  }

  const handleSearchReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...candidateQuery,
      ...{ search: { dataIndex: null } },
    }
    dispatch(setQuery(newQuery))
    dispatch(getCandidates(newQuery))
  }

  const columns: ColumnsType<Candidate> = [
    {
      fixed: 'left',
      key: 'name',
      title: intl.formatMessage({ id: 'ADMIN.TABLE.COLUMNS.NAME' }),
      sorter: true,
      width: 250,
      ...getColumnSearchProps(
        'lastName',
        searchInput,
        handleSearch,
        handleSearchReset,
        intl
      ),
      render: (record: Candidate) => <AvatarColumn candidate={record} />,
    },
    {
      key: 'interests',
      title: intl.formatMessage({ id: 'ADMIN.TABLE.COLUMNS.EXPERIENCES' }),
      filters: [
        ...jobs.map((job) => {
          return { text: job.name, value: `job-${job.id}` }
        }),
        {
          text: intl.formatMessage({
            id: 'ADMIN.TABLE.COLUMNS.EXPERIENCES_FILTER_LEVEL',
          }),
          value: 'experienceLevel',
          children: levels.map((level) => {
            return { text: level.name, value: `lvl-${level.id}` }
          }),
        },
      ],
      render: (record: Candidate) => (
        <>
          {record.interests.map((interest, index) => (
            <Tooltip key={index} title={interest.experienceLevel.name}>
              <Tag
                color={interest.experienceLevel.color}
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                {interest.job.name}
              </Tag>
            </Tooltip>
          ))}
          {record.interestsComment && (
            <Row style={{ marginTop: 5 }}>
              <Text
                style={{ width: COMMENTS_WIDTH }}
                ellipsis={{ tooltip: record.interestsComment }}
              >
                {record.interestsComment}
              </Text>
            </Row>
          )}
        </>
      ),
    },
    Table.EXPAND_COLUMN,
    {
      key: 'availabilities',
      title: intl.formatMessage({
        id: 'ADMIN.TABLE.COLUMNS.AVAILABILITY',
      }),
      filters: [
        ...days.map((day) => {
          return { text: day.label, value: `day-${day.id}` }
        }),
        {
          text: intl.formatMessage({
            id: 'ADMIN.TABLE.COLUMNS.AVAILABILITY_FILTER_TIMESLOT',
          }),
          value: 'timeSlots',
          children: timeSlots.map((timeSlot) => {
            return { text: timeSlot.label, value: `ts-${timeSlot.id}` }
          }),
        },
      ],
      render: (record: Candidate) => (
        <>
          <AvailabiltyColumn availabilities={record.availabilities} />
          {record.availabilitiesComment && (
            <Row style={{ marginTop: 5 }}>
              <Text
                style={{ width: COMMENTS_WIDTH }}
                ellipsis={{ tooltip: record.availabilitiesComment }}
              >
                {record.availabilitiesComment}
              </Text>
            </Row>
          )}
        </>
      ),
    },
    {
      key: 'documents',
      title: intl.formatMessage({
        id: 'ADMIN.TABLE.COLUMNS.DOCUMENTS',
      }),
      render: (record: Candidate) => (
        <Space direction="horizontal" size={'small'}>
          {record.documents.map((document) => (
            <Button
              key={document.id}
              icon={<FilePdfOutlined />}
              href={document.url}
              target="_blank"
            >
              {document.name}
            </Button>
          ))}
        </Space>
      ),
    },
    {
      key: 'status',
      title: intl.formatMessage({
        id: 'ADMIN.TABLE.COLUMNS.STATUS',
      }),
      render: (record: Candidate) => (
        <Space direction="horizontal">
          <StatusColumn candidate={record} />
          <DeleteCandidate id={record.id} />
        </Space>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Candidate> | SorterResult<Candidate>[]
  ) => {
    const newQuery = {
      pagination,
      filters: { ...candidateQuery.filters, ...filters },
      ...sorter,
    }

    dispatch(setQuery(newQuery))
    dispatch(getCandidates(newQuery))
  }

  const handleStatusFilterChange = (e: RadioChangeEvent) => {
    const optionSelected = e.target.value
    let filters = Object.assign({ ...candidateQuery.filters }, {})

    if (optionSelected !== 0) {
      filters = { ...filters, status: [optionSelected] }
    } else if (filters && filters.hasOwnProperty('status')) {
      delete filters.status
    }

    const newQuery = {
      ...candidateQuery,
      filters,
    }
    dispatch(setQuery(newQuery))
    dispatch(getCandidates(newQuery))
  }

  useEffect(() => {
    dispatch(getDays())
    dispatch(getJobs())
    dispatch(getStatus(statusQuery))
    dispatch(getTimeSlots())
    dispatch(getExperienceLevels())
    dispatch(getCandidates(candidateQuery))
  }, [])

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Candidates</Breadcrumb.Item>
      </Breadcrumb>

      <Card
        bordered={false}
        style={{ marginBottom: '10px' }}
        title={
          <Text>{intl.formatMessage({ id: 'ADMIN.STATUS_FILTER.TEXT' })}</Text>
        }
      >
        <Radio.Group defaultValue={0} onChange={handleStatusFilterChange}>
          <Radio key={0} value={0}>
            {intl.formatMessage({ id: 'ADMIN.STATUS_FILTER.ALL' })}
          </Radio>
          {statuses.map((status) => (
            <Radio key={status.id} value={status.id}>
              {status.name}
            </Radio>
          ))}
        </Radio.Group>
      </Card>

      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Table
          scroll={{ x: 1500 }}
          columns={columns}
          rowKey={(record) => record.id}
          expandable={AvailabiltiesNestedTable()}
          dataSource={candidates}
          pagination={candidateQuery.pagination}
          loading={isLoading}
          onChange={handleTableChange}
        />
      </Card>
    </>
  )
}

export default Candidates
