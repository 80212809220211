import { Card, Col, Row } from 'antd'

const CandidateDetails = () => {
  return (
    <Row style={{ maxWidth: 900, margin: 'auto' }}>
      <Col md={24}>
        <Card>ADMIN CandidateDetails</Card>
      </Col>
    </Row>
  )
}

export default CandidateDetails
